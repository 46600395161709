// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require("intersection-observer");
import Rails from '@rails/ujs';
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

// I18n must be loaded before other imports to ensure that translations are
// available while loading files that call I18n.t() during file load.
import I18n from "../i18n-js/instance";
window.I18n = I18n;

import LocalTime from "local-time";
LocalTime.start();

import "stylesheets/third_party.scss";
import "stylesheets/application.scss";
import "controllers";
import "src/timezone";
import tippy from "tippy.js";

const initialiseTippy = (container = document) => {
  const tip = tippy(container.querySelectorAll("[data-tippy-content]"), {
    allowHTML: true,
  });

  // Disable tippy on mobile
  document.addEventListener("touchstart", function handler() {
    tip.forEach(instance => {
      instance.destroy();
    });
    document.removeEventListener("touchstart", handler);
  });
};
window.initialiseTippy = initialiseTippy;
document.addEventListener("turbolinks:load", () => {
  window.initialiseTippy();

  // Scroll sidebar active space into position if below the fold
  const isIframePage = document.querySelectorAll(".iframe-mode")[0];

  if (isIframePage) {
    var links = document.getElementsByTagName("a");
    for (var i = 0, len = links.length; i < len; i++) {
      links[i].target = "_blank";
    }
  }

  // Remove show_back_link param
  if (window.location.href.includes("show_back_link=true")) {
    window.history.replaceState(
      {},
      document.title,
      location.href.split("?")[0],
    );
  }
});

document.addEventListener("turbolinks:click", function(event) {
  var anchorElement = event.target;
  var isSamePageAnchor =
    anchorElement.hash &&
    anchorElement.origin === window.location.origin &&
    anchorElement.pathname === window.location.pathname;

  if (isSamePageAnchor) {
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
      event.data.url,
      Turbolinks.uuid(),
    );
    event.preventDefault();
  }
});

// fix issue with navigation to an id/name.
document.addEventListener("turbolinks:load", () => {
  if (window.location.hash) {
    const tag = document.getElementById(window.location.hash.slice(1));
    if (tag) {
      tag.scrollIntoView(true);
      return;
    }
    const tags = document.getElementsByName(window.location.hash.slice(1));
    if (tags.length) {
      tags[0].scrollIntoView(true);
    }
  }
});
// Run instagram/facebook embeds
window.loadOembed = () => {
  window.instgrm?.Embeds.process();
  window.FB?.init({
    xfbml: true,
    version: "v12.0",
  });
  window.twttr?.widgets?.load();
};
document.addEventListener("turbolinks:load", () => {
  window.loadOembed();
});
window.webview = {
  // DEPRECATED: This is only used by the old webview
  isComingFromReactNativeWebview: false,
  isComingFromReactNativeAuthenticatedWebview: false,
  // use this one instead
  isInsideMobileWebview: false,
  electronAPI: {},
};
Rails.start();
